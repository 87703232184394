import { Alert, Box, Paper, Snackbar, Switch, Typography, useTheme, } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { getMemberSettingsRequest, ignitionNotificationRequest } from "../../../api/controllers/account-controller";


const Page = () => {
  const theme = useTheme();
  const [value, setValue] = useState(false)
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');


  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(false);
  };

  const fetchMe = async () => {
    try {
      let res = await getMemberSettingsRequest()
      if (res?.data?.notificationPermission === true) {
        setValue(res?.data?.notificationPermission)
        setAlert(true)
        setAlertSeverity("success")
        setAlertContent("Bildirimler Aktif")
      }
      else if (res?.data?.notificationPermission === false) {
        setValue(res?.data?.notificationPermission)
        setAlert(true)
        setAlertSeverity("success")
        setAlertContent("Bildirimler Kapalı")
      }

    } catch (error) {
      if (error?.response?.status === 400) {
        setAlert(true)
        setAlertSeverity("error")
      }
      else if (error?.response?.status === 401) {
        setAlert(true)
        setAlertContent("Giriş ekranına yönlendiriliyorsunuz!")
        setAlertSeverity("error")
        window.location.reload()
      }
      console.log(error)
    }
  }
  useEffect(async () => {
    setTimeout(async () => {
      await fetchMe()

    }, 1000);

    return () => {

    }
  }, [value])


  console.log(value)
  return (
    <Box p={3}>
      {alert ?
        <Snackbar open={alert} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} variant="filled" severity={alertSeverity} sx={{ width: '100%', backgroundColor: value === true ? "green" : "#C03A2A" }}>
            {
              Array.isArray(alertContent) ? alertContent && alertContent.map((val, i) => <li key={i}>{val.message}</li>) : alertContent && alertContent
            }
          </Alert>
        </Snackbar>
        : <></>
      }
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          padding: theme.spacing(2),
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          background: "#FBE9E7",
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
        }}
      >
        <Typography variant="h4" color="secondary">
          Ayarlar
        </Typography>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
          }}
        >
          {/* <Button onClick={
                        userType === "IndividualUser" ? () => setIndividualEditModalOpen(true) : () => setCorporateEditModalOpen(true)
                    }
                        disableElevation sx={{

                            margin: theme.spacing(1),
                            width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                        }} variant="contained" color="primary">Düzenle</Button> */}
        </div>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          padding: "30px",
          marginTop: theme.spacing(2),
        }}
      >
        <Typography color="#DC804F">Bildirim Ayarları </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          <Switch checked={value} onChange={async (i) => {
            setValue(i.target.checked)
            console.log(i.target.checked)
            let res = await ignitionNotificationRequest({ value: i.target.checked })


          }} />
          <Typography  >
            Kontak durumu değişikliğinde bildirim almak istiyorum
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Page;
