import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeRounded from '@mui/icons-material/HomeRounded';
import PersonRounded from '@mui/icons-material/PersonRounded';
import DirectionsBusRounded from '@mui/icons-material/DirectionsBusRounded';
import PeopleAltRounded from '@mui/icons-material/PeopleAltRounded';
import ExitToAppRounded from '@mui/icons-material/ExitToAppRounded';
import { logoutRequest } from '../api/admin-controllers/account-controller';
import { Link, useHistory } from "react-router-dom"
import WifiOffIcon from '@mui/icons-material/WifiOff';







const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function AdminPanelLayout({ children }) {
    const history = useHistory()
    const handleLogout = async () => {

        try {
            let res = await logoutRequest()
            if (res?.status === 200) {
                history.push("/admin")
            }
        } catch (error) {

        }
    }


    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{
                backgroundColor: "#3C3C3B"
            }} open={open}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box></Box>
                    <Link to="/admin/index" sx={{
                        justifySelf: "flex-end"
                    }}>
                        <IconButton onClick={handleLogout} title="Çıkış yap" color="inherit">
                            <ExitToAppRounded />
                        </IconButton>
                    </Link>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{ backgroundColor: "blue" }}>
                <DrawerHeader>
                    <Box padding="10px" display="flex" alignItems="center">
                        <img src="/assets/logo.svg" style={{ height: "auto", width: "100%" }} />
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <Link to="/admin/index">
                        <ListItem button >
                            <ListItemIcon>
                                <HomeRounded />
                            </ListItemIcon>
                            <ListItemText>
                                ANASAYFA
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <Link to="/admin/users">
                        <ListItem button >
                            <ListItemIcon>
                                <PersonRounded color="inherit" />
                            </ListItemIcon>
                            <ListItemText>
                                KİŞİLER
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <Link to="/admin/vehicles">
                        <ListItem button >
                            <ListItemIcon>
                                <DirectionsBusRounded color="inherit" />
                            </ListItemIcon>
                            <ListItemText>
                                ARAÇLAR
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/admin/non-signal-vehicles">
                        <ListItem button >
                            <ListItemIcon>
                                <WifiOffIcon color="inherit" />
                            </ListItemIcon>
                            <ListItemText>
                                SİNYALSİZ ARAÇLAR
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/admin/community">
                        <ListItem button >
                            <ListItemIcon>
                                <PeopleAltRounded color="inherit" />
                            </ListItemIcon>
                            <ListItemText>
                                GRUPLAR
                            </ListItemText>
                        </ListItem>
                    </Link>


                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box >
    );
}
