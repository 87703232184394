import { AppBar, Button, Grid, IconButton, Paper, Toolbar, useTheme, Tabs, Fade, Typography, Box, Tab, MenuItem, ListItemIcon, ListItemText, Menu } from "@material-ui/core"
import { Notifications, Close, ExpandMore, AccountCircle, AccountBox, Settings, ExitToApp, Menu as MenuIcon, HomeRounded } from "@material-ui/icons"
import { useState, useEffect } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { meRequest, logoutRequest } from "../api/controllers/account-controller"


const CustomLayout = ({ children }) => {

    const theme = useTheme()

    const location = useLocation()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [userName, setUserName] = useState("")
    const history = useHistory()




    const fetchAccountInformation = async () => {

        let res = await meRequest()
        if (res?.data) {
            //console.log(res?.data)

            setUserName(res?.data?.username)

        }
    }

    useEffect(() => {
        (
            async () => {
                await fetchAccountInformation()
            }
        )()

        return () => {

        }
    }, [])



    const NotificationBar = () => {
        const [notificationsOpen, setNotificationsOpen] = useState(false)
        const [tabValue, setTabValue] = useState("okunmamis-bildirimler")
        const TabPanel = (props) => props?.value === tabValue
            ? <Box>{props?.children}</Box>
            : <></>


        return (
            <Box
                onClickCapture={() => setNotificationsOpen(true)}
                onMouseLeave={() => setNotificationsOpen(false)}
                sx={{
                    marginRight: theme.spacing(1)
                }}
            >
                <Paper
                    elevation={0}
                >
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            borderRadius: "4px",
                            "&:hover": {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.primary.contrastText,
                            }
                        }}
                        color="primary"
                        variant="outlined"
                    >
                        <Notifications />
                    </IconButton>
                </Paper>
                <Fade
                    in={notificationsOpen}
                    unmountOnExit
                >
                    <Paper
                        elevation={3}
                        sx={{
                            position: "absolute",
                            zIndex: 99999,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            [theme.breakpoints.down("sm")]: {
                                top: 0,
                                left: 0,
                                width: "100vw",
                                height: "100vh",
                                padding: theme.spacing(3)
                            }, [theme.breakpoints.up('md')]: {
                                right: 10,
                                top: 50,
                                borderRadius: 5,
                                padding: theme.spacing(3),
                            },

                        }}
                    >
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: "none" } }}
                        >
                            <Typography variant="h5">Bildirimler</Typography>
                            <IconButton
                                onClick={() => setNotificationsOpen(false)}
                                style={{
                                    backgroundColor: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.contrastText,
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Grid>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>
                            <Tabs textColor="secondary" indicatorColor="secondary" value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} sx={{ width: "100%", }}>
                                <Tab value={"okunmamis-bildirimler"} sx={{ width: "50%" }} label="Okunmamış" />
                                <Tab value={"tum-bildirimler"} sx={{ width: "50%" }} label="Tümü" />
                            </Tabs>
                        </Box>
                        <TabPanel value={"okunmamis-bildirimler"}>
                            Item One
                        </TabPanel>
                        <TabPanel value={"tum-bildirimler"}>
                            Item Two
                        </TabPanel>
                    </Paper>
                </Fade>
            </Box>
        )
    }


    const CustomMenu = ({ items, openIcon, closedIcon, icon, expandable = false, link = "#", title, blinking }) => {

        const [menuOpen, setMenuOpen] = useState(false)
        const [anchorEl, setAnchorEl] = useState(null)
        const [isOpen, setIsOpen] = useState(false)

        function showIcon() {
            if (icon) {
                return icon;
            } else {
                if (isOpen) {
                    return openIcon;
                } else {
                    return closedIcon;
                }
            }
        }

        useEffect(() => {

            if (location?.pathname === link) {
                setIsOpen(true)
            } else if (String(location?.pathname).includes(link) && link !== "/app") {
                setIsOpen(true)
            } else {
                setIsOpen(false)
            }
            return () => {

            }
        }, [])



        return (
            <Grid item sx={{
                height: { xs: "9vh", sm: "9vh", md: "9vh", lg: "9vh" },
                margin: {
                    xs: theme.spacing(0, 1),
                    sm: theme.spacing(0, 0.5),
                    md: theme.spacing(0, 0.2)
                },
                display: "flex",
                alignItems: "center"
            }}>
                {
                    !expandable && link
                        ? <Link to={link}>
                            <Button
                                disableElevation
                                onClick={(e) => {
                                    if (!expandable) {
                                        return
                                    }
                                    setAnchorEl(e.currentTarget)
                                    setMenuOpen(true);
                                }}
                                startIcon={showIcon()}
                                variant={"contained"}
                                sx={{
                                    minHeight: "40px",
                                    backgroundColor: !isOpen && theme.palette.grey[200],
                                    color: !isOpen && theme.palette.primary.main,
                                    "&:hover": {
                                        backgroundColor: !isOpen && theme.palette.grey[400],
                                        color: !isOpen && theme.palette.primary.main,
                                    }
                                }}
                                color={isOpen ? "primary" : "secondary"}
                                endIcon={expandable && <ExpandMore />}
                            >
                                {title}

                                {
                                    blinking &&
                                    <Typography
                                        variant="body2"
                                        className="blink"
                                        sx={{
                                            textTransform: "none",
                                            padding: theme.spacing(0, 1),
                                            margin: theme.spacing(0, 1),
                                            borderRadius: "5px"
                                        }}
                                    >
                                        Canlı
                                    </Typography>
                                }
                            </Button>
                        </Link>
                        : <Button
                            disableElevation
                            onClick={(e) => {
                                if (!expandable) {
                                    return
                                }
                                setAnchorEl(e.currentTarget)
                                setMenuOpen(true);
                            }}
                            startIcon={showIcon()}
                            variant={"contained"}
                            sx={{
                                paddingX: { xs: "0px", md: "16px" },
                                minHeight: "40px",
                                backgroundColor: !isOpen && theme.palette.grey[200],
                                color: !isOpen && theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: !isOpen && theme.palette.grey[400],
                                    color: !isOpen && theme.palette.primary.main,
                                }
                            }}
                            color={isOpen ? "primary" : "secondary"}
                            endIcon={expandable && <ExpandMore />}
                        >
                            {title}
                        </Button>

                }


                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => {
                        setMenuOpen(false)
                        setAnchorEl(null)
                    }}
                    TransitionComponent={Fade}
                    sx={{
                        overflow: "hidden"
                    }}
                    PaperProps={{
                        elevation: 3,
                        style: {
                            width: "auto",
                            minWidth: anchorEl?.offsetWidth || "auto",
                        }
                    }}
                >
                    {
                        items && items.map((item, i) => <Link key={i} to={item?.link}>
                            <MenuItem >
                                {item?.icon &&
                                    <ListItemIcon>
                                        {item?.icon}
                                    </ListItemIcon>
                                }

                                <ListItemText>{item?.text}</ListItemText>
                            </MenuItem>
                        </Link>)
                    }

                </Menu>
            </Grid>
        )
    }


    return (
        <Box>
            <AppBar sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.common.black
            }}
                elevation={1}
                position="static"
            >
                <Toolbar >
                    <Grid container justifyContent="space-between" alignItems="center" flexDirection="row" >
                        <Grid item  >
                            <a href="/app">
                                <img src="/assets/logo.svg" style={{ height: "auto", width: "100%" }} />
                            </a>
                        </Grid>
                        <Grid item
                            sx={{
                                flexGrow: {
                                    xs: 1,
                                    md: 1,
                                },
                                display: { md: "none", xs: "flex" }
                            }}
                        >
                            <Grid container
                                justifyContent={{
                                    xs: "center",

                                }}
                                alignItems="center"
                            >
                                {/* <NotificationBar /> */}
                                <CustomMenu

                                    icon={<AccountCircle />}
                                    expandable
                                    items={[
                                        {
                                            text: "Hesabım",
                                            link: "/app/kullanici/hesabim",
                                            icon: <AccountBox fontSize="small" />
                                        },
                                        {
                                            text: "Ayarlar",
                                            link: "/app/kullanici/ayarlar",
                                            icon: <Settings fontSize="small" />
                                        },
                                        {
                                            text: "Çıkış Yap",
                                            link: "/app/logout",
                                            icon: <ExitToApp fontSize="small" />
                                        },

                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sx={{
                            display: {
                                xs: "flex",
                                sm: "none"
                            },
                            justifyContent: {
                                sm: "flex-end",
                            }
                        }} >
                            <IconButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)} >
                                <MenuIcon />
                            </IconButton>
                        </Grid>


                        <Grid item sx={{
                            flexGrow: 1,
                            transition: "0.2s",
                            overflow: "hidden",


                            height: {
                                xs: mobileMenuOpen ? "auto" : "0vh",
                                sm: "auto",
                                md: "auto"
                            },
                            opacity: {
                                xs: mobileMenuOpen ? 1 : 0,
                                sm: 1,
                                md: 1
                            }
                        }}>
                            <Grid container

                                justifyContent={{
                                    xs: "center",
                                    sm: "center",
                                    md: "flex-start"
                                }}
                                flexDirection={{
                                    xs: "row",
                                    sm: "row",
                                    md: "row"
                                }}
                                alignItems="center"
                                sx={{
                                    height: "100%",
                                }}

                            >

                                {/*  <CustomMenu
                                    title="Tanımlar"
                                    expandable
                                    link="/app/tanimlar"
                                    items={[
                                        {
                                            text: "Araçlar",
                                            link: "/app/tanimlar/araclar"
                                        },
                                        {
                                            text: "Güvenli Bölgeler",
                                            link: "/app/tanimlar/guvenli-bolgeler"
                                        },
                                        {
                                            text: "Kullanıcılar",
                                            link: "/app/tanimlar/kullanicilar"
                                        },
                                    ]}
                                /> */}
                                {/* <CustomMenu
                                    title="Harita"
                                    expandable
                                    link="/app/islemler"
                                    items={[
                                        {
                                            text: "Harita",
                                            link: "/app/islemler/harita"
                                        },
                                        {
                                            text: "Takip",
                                            link: "/app/islemler/takip"
                                        },
                                    ]}
                                /> */}

                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        md: "inline-flex"
                                    }
                                }}>
                                    <CustomMenu
                                        title="Raporlar"
                                        expandable
                                        items={[
                                            {
                                                text: "Hareket Raporu",
                                                link: "/app/raporlar/hareket-raporu"
                                            },
                                            {
                                                text: "Rota İzle",
                                                link: "/app/raporlar/rota-takip"
                                            },
                                            {
                                                text: "Araçlarım",
                                                link: "/app/tanimlar/araclar"
                                            }
                                        ]}
                                    />
                                </Box>
                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        md: "none"
                                    }
                                }}>
                                    <CustomMenu
                                        icon={<img src="/assets/Vector.svg" />}
                                        expandable
                                        items={[
                                            {
                                                text: "Hareket Raporu",
                                                link: "/app/raporlar/hareket-raporu"
                                            },
                                            /* {
                                                text: "Alarmlar",
                                                link: "/app/raporlar/alarmlar"
                                            } */
                                        ]}
                                    />
                                </Box>

                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        md: "inline-flex"
                                    }
                                }}>
                                    <CustomMenu
                                        title="Araç Takibi"
                                        link="/app/islemler/takip"
                                        blinking
                                    />
                                </Box>
                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        md: "none"
                                    }
                                }}>
                                    <CustomMenu
                                        icon={<img src="/assets/Frame.svg" />}
                                        link="/app/islemler/takip"
                                    />
                                </Box>

                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        md: "inline-flex"
                                    }
                                }}>
                                    <CustomMenu
                                        title="Rota Geçmişi"
                                        link="/app/islemler/gecmis_takip"
                                    />
                                </Box>

                                <Box sx={{
                                    display: {
                                        xs: "inline-flex",
                                        md: "none"
                                    },

                                }}>
                                    <CustomMenu
                                        openIcon={<img src="/assets/delivery2.svg" />}
                                        closedIcon={<img src="/assets/delivery3.svg" />}
                                        link="/app/islemler/gecmis_takip"

                                    />
                                </Box>



                            </Grid>
                        </Grid>






                        <Grid item
                            sx={{
                                flexGrow: {
                                    xs: 1,
                                    md: 0
                                },
                                display: { xs: "none", md: "flex" }
                            }}
                        >
                            <Grid container
                                justifyContent={{
                                    xs: "space-between"
                                }}
                                alignItems="center"
                            >
                                {/* <NotificationBar /> */}
                                <CustomMenu
                                    title={userName}
                                    icon={<AccountCircle />}
                                    expandable
                                    items={[
                                        {
                                            text: "Hesabım",
                                            link: "/app/kullanici/hesabim",
                                            icon: <AccountBox fontSize="small" />
                                        },
                                        {
                                            text: "Ayarlar",
                                            link: "/app/kullanici/ayarlar",
                                            icon: <Settings fontSize="small" />
                                        },
                                        {
                                            text: "Çıkış Yap",
                                            link: "/app/logout",
                                            icon: <ExitToApp fontSize="small" />,
                                        },
                                    ]}

                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar >
            <Box component="main" >
                {children}
            </Box>
        </Box >
    )
}


export default CustomLayout