import React from 'react'

const SuspenseFallback = () => {
    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <object height="200px" width="200px" type="image/svg+xml" data="/assets/serLoading.svg"></object>
            {/*   <img
                src={"/assets/logo.svg"}
                height="300px"
                width="300px"
                alt="Logo"
            />
 */}
        </div>
    )
}

export default SuspenseFallback