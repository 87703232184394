import {
	Alert,
	Box,
	Grid,
	Input,
	Snackbar,
	Typography
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
	findAllVehicleWithoutSignalRequest
} from '../../api/admin-controllers/vehicle-controller';

export async function getServerSideProps() {
	return { props: {} };
}

const Page = () => {

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [tableLoading, setTableLoading] = useState(false);

	//ALERTS FUNC
	const [alert, setAlert] = useState(false);
	const [alertContent, setAlertContent] = useState('');
	const [alertSeverity, setAlertSeverity] = useState('');

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlert(false);
	};


	//DATAGRID STATES
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [totalCount, setTotalCount] = useState();
	const [searchInput, setSearchInput] = useState('');


	//GET VEHİCLES
	const fetchVehicles = async () => {
		setTableLoading(true);
		try {
			let res = await findAllVehicleWithoutSignalRequest();
			if (res?.data && res.data.length > 0) {
				setData(res.data);
				setFilteredData(res.data)
				setTotalCount(res.data?.length);
				setTableLoading(false);
			}
		} catch (error) {
			setTableLoading(false);
			if (error?.response?.status === 400) {
				setAlert(true);
				setAlertSeverity('error');
				if (Array.isArray(error.response.data.data)) {
					setAlertContent(error?.response?.data?.data);
				} else {
					setAlertContent(new Array(error?.response?.data));
				}
			} else {
				setAlert(true);
				setAlertContent('Sistem yöneticiniz ile iletişime geçiniz!');
				setAlertSeverity('error');
			}
		}
	};

	const filterVehicles = () => {
		setPage(0)
		const filteredVehicles = data.filter(vehicle =>
			vehicle?.imei?.includes(searchInput) || vehicle?.licensePlate?.includes(searchInput)
		);
		setFilteredData(filteredVehicles)
		setTotalCount(filteredVehicles?.length);
	}


	useEffect(() => {
		if (searchInput && searchInput !== "" && data && data.length > 0) {
			filterVehicles();
		} else {
			if (data && data.length > 0) {
				setFilteredData(data)
				setTotalCount(data?.length);
			} else {
				fetchVehicles()
			}
		}

	}, [searchInput]);



	//DATAGRID DATAS
	const columns = [
		{
			field: 'licensePlate',
			headerName: 'Plaka',
			flex: 3,
			minWidth: 130,
		},
		{
			field: 'imei',
			headerName: 'İmei',
			flex: 3,
			minWidth: 130,

		},
		{
			field: 'lastSignalDateTime',
			headerName: 'Son Sinyal Tarihi',
			flex: 3,
			minWidth: 130,
		},

	];


	return (
		<Box>

			{alert ?
				<Snackbar open={alert} autoHideDuration={10000}
					onClose={handleCloseAlert}>
					<Alert onClose={handleCloseAlert} variant="filled"
						severity={alertSeverity}
						sx={{ width: '100%' }}>
						{
							Array.isArray(alertContent) ? alertContent && alertContent.map((val, i) =>
								<li key={i}>{val.message}</li>) : alertContent && alertContent
						}
					</Alert>
				</Snackbar>
				: <></>
			}

			<Grid container spacing={2}>
				<Grid item md={12} xs={12}>
					<Card variant="outlined" sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '1rem'
					}}>
						<Typography sx={{ fontSize: '24px' }}
							color="dark">30 DAKİKA SİNYAL
							ALINAMAYAN ARAÇLAR</Typography>
					</Card>
				</Grid>
				<Grid item md={12} xs={12}>
					<Card variant="outlined">
						<Input
							sx={{
								margin: '1rem',
								width: {
									xs: '85%',
									sm: '85%',
									md: '30%'
								}
							}}
							placeholder="Aramak istediğiniz kelimeyi yazın..."
							onChange={(e) =>
								setTimeout(() => {
									setSearchInput(e.target.value);
								}, 500)}
						/>
						<div
							style={{ height: '60vh', width: '100%' }}>
							{
								tableLoading ?
									<Box style={{
										width: '100%',
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
										<object height="150px"
											width="150px"
											type="image/svg+xml"
											data="/assets/serLoading.svg"></object>
									</Box> :
									<DataGrid
										rows={filteredData}
										columns={columns}
										page={page}
										pageSize={pageSize}
										onPageSizeChange={(newPageSize) => {
											setPageSize(newPageSize);
											setPage(0);
										}
										}
										onPageChange={(newPage) => {
											setPage(newPage);
										}
										}

										rowsPerPageOptions={[10, 25, 50, 100]}
										pagination
										/* paginationMode="server" */
										rowCount={totalCount}
										disableSelectionOnClick
										disableColumnMenu
									/>
							}
						</div>

					</Card>
				</Grid>
			</Grid>


		</Box>

	);
};

export default Page;