import { useState, useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import { meRequest } from "../api/controllers/account-controller"
import { getCurrentUserRequest } from "../api/controllers/home-controller"
import CustomLayout from "./CustomLayout"
import SuspenseFallback from "./SuspenseFallback"



const PrivateRoute = ({ component: Component, ...rest }) => {


    const [AUTH_STATUS, setAUTH_STATUS] = useState("WAITING")



    const checkAuthentication = async () => {
        // setAUTH_STATUS("SUCCESS")
        // return
        try {
            let res = await meRequest()
            if (res?.data) {
                //console.log(res?.data)
                setTimeout(() => {
                    setAUTH_STATUS("SUCCESS")
                }, 1000);
            }
        } catch (error) {
            //console.log("auth hatası", error)
            if (error?.response?.status === 401) {
                //console.log("LOGİN DEĞİLSİN")
                setAUTH_STATUS("FAILED")
            }
        }


    }

    useEffect(() => {
        (
            async () => {
                await checkAuthentication()
            }
        )()
        return () => {

        }
    }, [])



    return (
        <Route  {...rest}>
            {
                AUTH_STATUS === "WAITING" ? <SuspenseFallback />
                    : AUTH_STATUS === "FAILED" ? <Redirect to={{ pathname: "/login" }} />
                        : AUTH_STATUS === "SUCCESS" ? <CustomLayout> <Component /></CustomLayout>
                            : <div>Error</div>
            }
        </Route>

    )
}

export default PrivateRoute